<template>
  <div class="tile is-ancestor">
    <div class="tile is-parent">
      <div class="tile is-child">
        <div class="content">
          <p>
            Manage SharePoint sites, lists, documents, and permissions
            using Power Automate, Logic Apps or REST API.
          </p>

          <h3>Use it in Power Automate</h3>
          <ol>
            <li>
              Create a new
              <a :href="`${appUrlEndpoint}actions/api-keys`">API key</a>.
            </li>
            <li>
              Create a new Power Automate Flow and add actions from
              <a href="https://flow.microsoft.com/en-us/connectors/shared_plumsailsp/plumsail-sp/">
                Plumsail SP connector
              </a>.
            </li>
            <li>
              Review
              <a href="https://plumsail.com/docs/actions/v1.x/getting-started/use-from-flow.html#examples-of-flows">
                the list of ready flow examples
              </a>.
            </li>
          </ol>

          <h3>Use as rest API</h3>
          <ol>
            <li>Create a new <a :href="`${appUrlEndpoint}actions/api-keys`">API key</a>.</li>

            <li>
              Learn available operations in
              <a :href="`${workersEndpoint}swagger/index.html?urls.primaryName=SharePoint`">
                API reference
              </a>.
            </li>
          </ol>

          <h3>Additional resources</h3>
          <p>
            <ul>
              <li>
                <a
                  href="https://plumsail.com/docs/actions/v1.x/index.html"
                  target="_blank"
                >
                  Documentation
                </a>
              </li>
              <li>
                <a
                  href="https://community.plumsail.com/c/actions"
                  target="_blank"
                >Community</a>
              </li>
              <li>
                <a
                  href="https://medium.com/plumsail"
                  target="_blank"
                >
                  Blog
                </a>
              </li>
              <li>
                <a
                  href="https://plumsail.com/actions/support/"
                  target="_blank"
                >
                  Support
                </a>
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { workersEndpoint, appUrlEndpoint } from '@/utils/url-manager.js';

export default {
  data() {
    return {
      appUrlEndpoint,
      workersEndpoint,
    };
  },
};
</script>
